import { trackClick } from '@/utils/track';

class InitUmLink {
  constructor(params) {
    this.selector = params.selector;
    this.linkId = params.linkId;
    this.pageTitle = params.pageTitle || '落地页';
    this.clipboardText = params.clipboardText;
    this.isUmLinkInitSuccess = false;
    this.traceId = params.traceId || '';
    this.clipboardInfo = params.clipboardText;
    this.ext = params.ext || {};
    this.$_init();
  }
  $_init() {
    if (!this.selector || !this.linkId) {
      this.isUmLinkInitSuccess = false;
      return;
    }
    // const ULinkSDKURL = document.createElement('script');
    // ULinkSDKURL.type = 'text/javascript';
    // ULinkSDKURL.src = 'https://share.umeng.com/demo/ulink/index.min.js?v=1.1.3-16709143';
    // ULinkSDKURL.onload = () => {
    //   let { ULink } = window;
    //   if (/android/i.test(window.navigator.userAgent)) {
    //     ULink.tracker.setMetaInfo({
    //       appkey: '5d91d0ed570df3d8ed000cb9',
    //     });
    //   }
    // const musicCode = this.ext ? this.ext.id : 'testcode';
    // let { ULink } = window;
    // const data = {
    //   id: this.linkId,
    //   data: this.ext,
    //   selector: this.selector,
    //   auto: false,
    //   timeout: 2000,
    //   laze: false,
    //   useOpenInBrowerTips: 'default',
    //   useClipboard: (clipboardToken) => this.clipboardFn(clipboardToken),
    //   proxyOpenDownload: (defaultAction, LinkInstance) => this.openDownload(defaultAction, LinkInstance),
    //   onready: (ctx) => this.linkReady(ctx),
    // };
    // ULink([data]);
  }
  // document.body.appendChild(ULinkSDKURL);
  // }
  linkReady(ctx) {
    console.log('ready', ctx);
    if (ctx.solution && ctx.solution.appkey) {
      this.isUmLinkInitSuccess = true;
      this.clipboardInfo = `${this.clipboardText}${ctx.solution.clipboardToken};${this.traceId}`;
      trackClick({ page_title: 'um_ulink', element_name: this.pageTitle, remarks: 'success' });
    } else {
      this.isUmLinkInitSuccess = false;
      trackClick({ page_title: 'um_ulink', element_name: this.pageTitle, remarks: 'fail' });
    }
  }
  clipboardFn(clipboardToken) {
    let str = `${this.clipboardText}${clipboardToken}`;
    if (this.traceId) {
      str += `;${this.traceId}`;
    }
    return str;
  }
  openDownload(defaultAction, LinkInstance) {
    if (LinkInstance.solution.type === 'scheme') {
      window.location.href = LinkInstance.solution.downloadUrl;
    } else if (LinkInstance.solution.type === 'universalLink') {
      // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟已于6月2日上线universalLink生成及重定向功能。
    }
  }
}
export default InitUmLink;
