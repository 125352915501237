<template>
  <div v-show="shareArticleInfo.title" class="container">
    <div class="invite-wrap">
      <div class="invite-cont">
        <img class="logo-img" src="@/assets/images/learn-money/logo.png" alt="" />
        <div class="invite-info">你的好友邀请您一起学知识</div>
        <div :data-clipboard-text="inviteCode" class="look-btn" id="look-btn" @click="openApp('#look-btn')">
          查看真相
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="article-title">{{ shareArticleInfo.title }}</div>
      <img class="content-img" :src="shareArticleInfo.picture" alt="内容图" />
      <div class="article-content">{{ shareArticleInfo.question }}</div>
      <div :data-clipboard-text="inviteCode" class="open-btn" id="open-btn" @click="openApp('#open-btn')">
        打开快音，学习知识
      </div>
    </div>
  </div>
</template>

<script>
  import Download from '@/utils/download';
  import Clipboard from 'clipboard';
  import { mapGetters } from 'vuex';
  import API from '@/api';
  import InitUmLink from '@/utils/init-ulink';

  export default {
    async asyncData({ store, $http, params }) {
      let { article_code: code } = params;
      const data = await $http.post(API.GET_SHARE_ARTICLE_PAGE_INFO, {
        code,
      });
      store.commit('share/learnMoney/SET_SHARE_PAGE_INFO', data);
    },
    components: {},
    props: {},
    data() {
      return {
        sharePosition: this.$route.query.share_position || 'learn-make-money',
        inviteCode: ``,
        isUmLinkInitSuccess: false,
      };
    },
    computed: {
      ...mapGetters('share/learnMoney', ['shareArticleInfo']),
    },
    created() {},
    mounted() {
      let { invite_code } = this.$route.query;

      if (invite_code) {
        this.inviteCode = `邀请码【${invite_code}】`;
      }
      document.title = '学知识';
      this.initULink();
    },
    methods: {
      /**
       * @description: 初始化友盟 ULink SDK
       */

      initULink() {
        const selector = `#look-btn, #open-btn`;
        new InitUmLink({
          selector,
          linkId: 'usr13qes8564qffq',
          pageTitle: '边学边赚钱落地页',
          clipboardText: this.inviteCode,
          traceId: this.traceId,
        });
      },
      /**
       * @description 下载APP.
       * @param {string} element - 元素id.
       */

      openApp(element) {
        const clipboard = new Clipboard(element);

        try {
          const jump = () => {
            let url;

            if (this.$platform.Android) {
              url = Download.downLoad('yyb');
              // 尝试打开APP
              window.location.href = this.getOpenUrl();
            } else if (this.$platform.iOS) {
              url = Download.downLoad('share_ios');
            } else {
              url = Download.downLoad(this.sharePosition, { mode: 'divide' });
            }

            setTimeout(() => {
              window.location.href = url;
            }, 500);
          };

          this.$toast({
            message: '亲~正在为你拉起下载页',
            duration: 5000,
            overlay: false,
          });

          // 使用友盟U-link
          // if (process.env.NODE_ENV !== 'development' && this.isUmLinkInitSuccess) {
          //   return;
          // }

          clipboard.on('success', (e) => {
            e.clearSelection();
            jump();
          });
          clipboard.on('error', () => {
            jump();
          });
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },

      /**
       * @description 尝试打开APP的地址.
       */

      getOpenUrl() {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let url;

        if (process.env.NODE_ENV === 'test') {
          url = `http://h5.rd.kaixinyf.cn/learn-make-money`;
        } else if (process.env.NODE_ENV === 'production') {
          url = `https://h5.kaixinyf.cn/learn-make-money`;
        }

        return `${SCHEME}://web?web_url=${url}`;
      },
    },
  };
</script>

<style lang="less" scoped>
  .container {
    overflow: scroll;

    .invite-wrap {
      padding: 0 0.3rem;
      position: fixed;
      top: 0;
      box-sizing: border-box;
      width: 100%;
      background: #fff;

      .invite-cont {
        display: flex;
        align-items: center;
        border-bottom: 0.01rem solid #eee;
        height: 1rem;
        width: 100%;

        .logo-img {
          width: 0.6rem;
          height: 0.6rem;
        }

        .invite-info {
          flex: 1;
          font-size: 0.32rem;
          padding: 0 0.2rem;
          font-weight: 400;
          color: #313131;
        }

        .look-btn {
          width: 1.2rem;
          height: 0.4rem;
          border: 0.01rem solid #ff2b3d;
          border-radius: 0.4rem;
          color: #ff2b3d;
          font-size: 0.22rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
        }
      }
    }

    .content-wrap {
      padding: 1.2rem 0.4rem 0 0.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .article-title {
        font-size: 0.4rem;
        width: 100%;
        font-weight: 600;
        color: #333333;
      }

      .content-img {
        width: 100%;
        height: 3.66rem;
        margin-bottom: 0.2rem;
      }

      .article-content {
        font-size: 0.32rem;
      }

      .open-btn {
        width: 5.28rem;
        height: 0.8rem;
        background: #ff2b3d;
        font-size: 0.32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.8rem;
        margin: 1rem 0;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
</style>
